import About from "./components/about";
import Contact from "./components/contact";
import Education from "./components/education";
import Experience from "./components/experience";
import NavBar from "./components/navbar";
import Projects from "./components/projects";
import SectionTitle from "./components/section_title";
import Terminal from "./components/terminal";

function App() {
  return (
    <div>
      <div className="min-h-screen text-gray-800 font-space_mono bg-gradient-to-b from-stone-100 to-stone-600">
        <section id="home">
          <NavBar />
        </section>
        <section id="terminal" className="pt-40">
          <Terminal />
        </section>
        <section id="about" className="">
          <SectionTitle title="about" />
          <About />
        </section>
        <section id="experience" className="mt-60">
          <SectionTitle title="experience" />
          <Experience />
        </section>
        <section id="projects" className="mt-60">
          <SectionTitle title="projects" />
          <Projects />
        </section>
        <section id="education" className="text-white mt-60">
          <SectionTitle title="education" />
          <Education />
        </section>
        <section id="contact" className="text-white mt-32">
          <SectionTitle title="contact" />
          <Contact />
        </section>
      </div>
    </div>
  );
}

export default App;
