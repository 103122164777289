interface SectionTitleProps {
  title: string;
}

export default function SectionTitle({ title }: SectionTitleProps) {
  return (
    <div>
      <div className="flex justify-center text-xl py-5">{title}</div>
    </div>
  );
}
