import EducaitonEntry from "./education_entry";

export default function Education() {
  return (
    <div className="flex justify-center text-xl py-5 italic px-16">
      <ol className="flex flex-col lg:w-4/5 relative border-l border-green-200 dark:border-green-700">
        <EducaitonEntry
          schoolName="Lodz University of Technology"
          field="Computer Science"
          startDate="2021"
          endDate="2023"
          degree="Master's Degree"
        />
        <EducaitonEntry
          schoolName="Lodz University of Technology"
          field="Computer Science"
          startDate="2017"
          endDate="2021"
          degree="Bachelor's Degree"
        />
        <EducaitonEntry
          schoolName="Technical College"
          field="Computer Science"
          startDate="2017"
          endDate="2013"
          degree="IT specialist"
        />
      </ol>
    </div>
  );
}
