import { GithubOriginal, LinkedinOriginal } from 'devicons-react';

export enum ButtonType {
  Github = "github",
  Linkedin = "linkedin",
}

interface ContactButtonProps {
  type: ButtonType;
  link: string;
}


const getButton = (props: ContactButtonProps): JSX.Element => {
  switch (props.type) {
    case ButtonType.Github:
      return (
        <button
          onClick={() => {
            window.open(props.link, "_blank");
          }}
          title={props.link}
          type="button"
          className="text-gray-900 hover:text-white border border-gray-800 bg-green-50 hover:bg-green-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
        >
        <div className=''>
        <GithubOriginal size={24}/>
        </div>
        </button>
      );
    case ButtonType.Linkedin:
      return (
        <button
          type="button"
          title={props.link}
          onClick={() => {
            window.open(props.link, "_blank");
          }}
          className="text-gray-900 hover:text-white border border-gray-800 bg-blue-50 hover:bg-blue-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
        >
        <LinkedinOriginal size={24}/>
        </button>
      );
  }
};

export default function ContactButton(props: ContactButtonProps) {
  return getButton(props);
}
