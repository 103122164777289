import ContactButton, { ButtonType } from "./contact_button";

export default function ContactButtonsBar() {
  return (
    <div className="flex flex-row justify-center flex-wrap">
      <ContactButton type={ButtonType.Github} link="https://github.com/GGuinea" />
      <ContactButton type={ButtonType.Linkedin}  link="https://linked.in/zielinskikamil55"/>
    </div>
  );
}
