import { useState } from "react";
import NavbarElement from "./navbar_element";
import { Transition } from "@headlessui/react";

const get_navbar_elements = (setIsOpen : any) => {
  return (
    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
    onClick={() => setIsOpen(false)}
    >
      <NavbarElement title={"home"} path={"#terminal"} />
      <NavbarElement title={"experience"} path={"#experience"} />
      <NavbarElement title={"projects"} path={"#projects"} />
      <NavbarElement title={"school"} path={"#education"} />
      <NavbarElement title={"contact"} path={"#contact"} />
    </ul>
  );
};

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <nav className="bg-white border-gray-200 dark:bg-gray-900 fixed px-8 w-full z-20 top-0 left-0 border-b-2 opacity-90 scroll-smooth">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
          <span className="self-center text-xl whitespace-nowrap dark:text-white">
            kamil zieliński
          </span>
          <div className="flex items-center justify-between h-16">
            <div className="flex flex-row">
              <div className="hidden md:block">{get_navbar_elements(setIsOpen)}</div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pb-3  sm:px-3">
                {get_navbar_elements(setIsOpen)}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}
