import ProjectEntry from "./project_entry";
import image from "../img/move_app.png";

export default function Projects() {
  return (
    <div className="max-w-screen-2xl m-auto">
      <div className="flex flex-wrap justify-center mt-10">
        <ProjectEntry
          title="Movies Browser"
          shortDescription="Integrated with IMDb movies browers"
          longDescription="bla bla bla"
          technologies={["React", "TypeScript", "Tailwind CSS"]}
          image={image}
        />
        <ProjectEntry
          title="Movies Browser"
          shortDescription="Integrated with IMDb movies browers"
          longDescription="bla bla bla"
          technologies={["React", "TypeScript", "Tailwind CSS"]}
          image={image}
        />
        <ProjectEntry
          title="Movies Browser"
          shortDescription="Integrated with IMDb movies browers"
          longDescription="bla bla bla"
          technologies={["React", "TypeScript", "Tailwind CSS"]}
          image={image}
        />
        <ProjectEntry
          title="Movies Browser"
          shortDescription="Integrated with IMDb movies browers"
          longDescription="bla bla bla"
          technologies={["React", "TypeScript", "Tailwind CSS"]}
          image={image}
        />
        <ProjectEntry
          title="Movies Browser"
          shortDescription="Integrated with IMDb movies browers"
          longDescription="bla bla bla"
          technologies={["React", "TypeScript", "Tailwind CSS"]}
          image={image}
        />
        <ProjectEntry
          title="Movies Browser"
          shortDescription="Integrated with IMDb movies browers"
          longDescription="bla bla bla"
          technologies={["React", "TypeScript", "Tailwind CSS"]}
          image={image}
        />
      </div>
    </div>
  );
}
