interface ExperienceEntryProps {
  title: string;
  company: string;
  location: string;
  startDate: string;
  endDate: string;
  description: string[];
  highlights: string[];
  additional: string[];
}

export default function ExperienceEntry(props: ExperienceEntryProps) {
  return (
    <li>
      <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-2.5 -left-1.5 border border-green-600 dark:border-zinc-900 dark:bg-zinc-700"></div>
      <time className="ml-2 text-sm font-normal leading-none ">
        {props.startDate} - {props.endDate}
      </time>
      <h3 className="ml-2 mb-2 text-lg font-semibold ">
        {props.title} - {props.company}
      </h3>
      <p className="ml-2 mb-4 text-base font-normal">
        {" "}
        {props.description.map((line) => {
          return (
            <li>
              {line}.<br />
            </li>
          );
        })}
      </p>
      <p className="ml-2 text-base font-normal ">
        {" "}
        {props.highlights.map((line, index) => {
          if (index === props.highlights.length - 1) {
            return line;
          } else {
            return line + ", ";
          }
        })}
      </p>

      <p className="ml-2 mb-4 text-base font-normal ">
        {" "}
        {props.additional.map((line, index) => {
          if (index === props.additional.length - 1) {
            return line;
          } else {
            return line + ", ";
          }
        })}
      </p>
    </li>
  );
}
