interface EducationEntryProps {
  schoolName: string;
  field: string;
  startDate: string;
  endDate: string;
  degree: string;
}

export default function EducaitonEntry(props: EducationEntryProps) {
  return (
    <li>
      <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-2.5 -left-1.5 border border-green-600 dark:border-zinc-900 dark:bg-zinc-700"></div>
      <time className="ml-2 text-sm font-normal leading-none ">
        {props.startDate} - {props.endDate}
      </time>
      <h3 className="ml-2 mb-2 text-lg">
        {props.field} - {props.degree}
      </h3>
      <p className="ml-2 mb-4 text-sm font-normal"> {props.schoolName}</p>
    </li>
  );
}
