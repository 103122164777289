import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ContactButtonsBar from "./contact_buttons";

export default function Contact() {
  const [email, setEmail] = useState("");
  const notify = () => toast("E-mail copied!");
  useEffect(() => {
    return setEmail(process.env.REACT_APP_EMAIL!);
  }, []);

  return (
    <div className="flex justify-center text-xl py-5 italic">
      <div className="flex flex-col lg:w-4/5 w-full px-12">
        <ContactButtonsBar />
        <button
          title={email}
          onClick={() => {
            navigator.clipboard.writeText(email);
            notify();
          }}
          data-tooltip-target="tooltip-click"
          data-tooltip-trigger="click"
          type="button"
          className="text-gray-900 bg-yellow-50 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
        >
          Copy adress email
        </button>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="light"
        />
        <div className="flex pt-10 text-xs justify-center">by kamil</div>
      </div>
    </div>
  );
}
