interface NavbarElementProps {
  path: string;
  title: string;
}

export default function NavbarElement({ path, title }: NavbarElementProps) {
  return (
    <li>
      <a
        href={path}
        className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-700 md:p-0 dark:text-white md:dark:hover:text-green-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
      >
        {title}
      </a>
    </li>
  );
}
