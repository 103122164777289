import { useState, useRef, useEffect } from "react";

interface Command {
  name: string;
  description: string;
  action: () => string;
}

const COMMANDS: Command[] = [
  {
    name: "help",
    description: "Display the list of available commands",
    action: () => {
      return (
        "Available commands:\n" +
        COMMANDS.map(
          ({ name, description }) => `${name} - ${description}`
        ).join("\n")
      );
    },
  },
  {
    name: "echo",
    description: "Print a message to the terminal",
    action: () => {
      return "ECHO";
    },
  },
];

export default function Terminal() {
  const [input, setInput] = useState("");
  const [history, setHistory] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [history]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const [commandName, ...args] = input.split(" ");

    const command = COMMANDS.find(({ name }) => name === commandName);

    if (!command) {
      setHistory([...history, input]);
      setInput("");
      return;
    }

    let output = command.action();
    setHistory([...history, output]);

    setInput("");
  };

  return (
    <div className="flex w-full justify-center border-zinc-950 px-12">
      <div className="flex flex-col lg:w-3/5 text-sm w-full h-80 bg-zinc-950 text-white font-mono border-zinc-800 border-2">
        <div
          ref={containerRef}
          className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 p-2"
        >
          <pre className="whitespace-pre-wrap scrollItscroltext-green-400">
            {history.map(function (name, _index) {
              console.log(name);
              return name + "\n";
            })}
          </pre>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex items-center bg-zinc-950 p-2"
        >
          <span className="text-green-400">&gt;</span>
          <input
            type="text"
            autoCapitalize="off"
            value={input}
            placeholder="enter a command..."
            onChange={(event) => setInput(event.target.value)}
            className="flex-1 text-xs bg-transparent pl-1 border-none outline-none"
          />
        </form>
      </div>
    </div>
  );
}
