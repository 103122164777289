export default function About() {
  return (
    <div className="flex justify-center text-xl py-5 italic">
      <div className="flex flex-col lg:w-4/5 w-full px-12">
        I am a passionate and open-minded software engineer who welcomes new
        challenges. <br />
        My primary focus is on backend engineering, and I am comfortable working
        with various technologies and environments.
      </div>
    </div>
  );
}
