import ExperienceEntry from "./experience_entry";

export default function Experience() {
  return (
    <div className="flex justify-center text-xl py-5 italic px-16 mt-10">
      <ol className="flex flex-col lg:w-4/5 relative border-l border-green-200 dark:border-green-700">
        <ExperienceEntry
          title="Backend Egineer"
          company="AppUnite"
          location="Remote"
          startDate="07.2022"
          endDate="current"
          description={[
            "Creating, testing and documenting new feature according to client requirements and good practices",
            "Daily cooperation with foreign clients",
          ]}
          highlights={[
            "Elixir",
            "JavaScript",
            "PostgeSQL",
            "Elasticsearch",
            "Docker",
            "AWS",
          ]}
          additional={["Phoenix", "LiveView", "Tailwind CSS", "SurfaceUI"]}
        />

        <ExperienceEntry
          title="Backend Egineer"
          company="BlueSoft Sp. z o.o."
          location="Remote"
          startDate="02.2021"
          endDate="06.2022"
          description={[
            "Creating, testing and documenting new feature according to client requirements and good practices",
            "Legacy code bug fixing and refactoring",
            "Technological leader (maintenance of production environments)",
            "Defining business analysis products",
            "Participating in the software development as a project leader",
            "Daily cooperation with client",
          ]}
          highlights={[
            "Java",
            "Python",
            "PostgeSQL",
            "Linux",
            "Docker",
            "SQLite",
            "AWS",
          ]}
          additional={["Spring Boot", "Maven", "Jira", "Confluence", "Swagger"]}
        />

        <ExperienceEntry
          title="Junior Software Engineer"
          company="Cybercom"
          location="Lodz/Remote"
          startDate="07.2019"
          endDate="01.2021"
          description={[
            "Creating, testing and documenting software according to requirements and 3GPP standards",
            "Working in international team",
            "Participating in code review",
          ]}
          highlights={["Java", "C++", "Spring", "Python", "Bash", "Linux"]}
          additional={["Spring", "Maven", "Jira", "Confluence", "Agile"]}
        />

        <ExperienceEntry
          title="Cooperation between TUL and Cybercom"
          company="Cybercom"
          location="Lodz"
          startDate="02.2019"
          endDate="06.2021"
          description={[
            "Designing and implementing software for 5G network",
            "Implementing PDCP layer for 5G according to 3GPP standards",
          ]}
          highlights={["C++", "Python", "Bash", "Linux"]}
          additional={["Git", "Make", "Jira", "Confluence"]}
        />
      </ol>
    </div>
  );
}
